<template>
  <div class="flex-lg-row-fluid">
    <!--begin:::Tabs-->
    <ul
      class="
        nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
        border-0
        fs-4
        fw-semibold
        mb-8
      "
      role="tablist"
    >
      <!--begin:::Tab item-->
      <li class="nav-item" role="presentation">
        <a
          class="nav-link text-active-primary pb-4 active"
          data-bs-toggle="tab"
          href="#kt_customer_view_overview_tab"
          aria-selected="true"
          role="tab"
          >Aktif Mülakatlar</a
        >
      </li>
      <!--end:::Tab item-->

      <!--begin:::Tab item-->
      <li class="nav-item" role="presentation">
        <a
          class="nav-link text-active-primary pb-4"
          data-bs-toggle="tab"
          href="#kt_customer_view_overview_events_and_logs_tab"
          aria-selected="false"
          role="tab"
          tabindex="-1"
          >Pasif Mülakatlar</a
        >
      </li>
      <!--end:::Tab item-->
    </ul>
    <!--end:::Tabs-->

    <!--begin:::Tab content-->
    <div class="tab-content" id="myTabContent">
      <!--begin:::Tab pane-->
      <div
        class="tab-pane fade active show"
        id="kt_customer_view_overview_tab"
        role="tabpanel"
      >
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="card-title mt-8">
                      <h2>Hybrid Çalışan Frontend Developer</h2>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-3
                      justify-content-end
                      text-align-end
                      align-items-end
                    "
                  >
                  <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      mt-10

                    "
                    >İncele</a
                  ></router-link
                >
                  </div>
                </div>
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>İlk Mülakat Aşaması</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat, yazılım geliştirme pozisyonu için adayların teknik becerilerini ve deneyimlerini değerlendirir.
                </div>
                <p> <b>Tarih: 08.11.2024 - 01.01.2025</b> </p>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 5
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Minimum 3 yıl
                    iş tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    mühendislik bölümlerinden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Vuejs,
                    Typescript ile tecrübesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Tercihen
                    Angular ve React ile tecrübesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Ekip
                    çalışmasını seven ve Agile metodunu benimseyen
                  </div>

                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>
                    <em>7 adet daha..</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">

               <router-link to="/interview/invite-candidate">
                  <a class="btn btn-sm btn-primary btn-active-primary my-1 ms-2                      me-2"
                    >Aday Davet Et</a
                  ></router-link
                >
                <a class="btn btn-sm btn-secondary btn-active-primary my-1                      me-2"
     >Kopyala</a
   >

                <button
                  type="button"
                  class="btn btn-sm btn-warning btn-active-light-primary my-1"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role"
                >
                <span class="svg-icon svg-icon-3 me-0">
                      <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </button>

                <button
                  type="button"
                  class="btn btn-sm btn-danger btn-active-danger my-1 ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role"
                >
                <span class="svg-icon svg-icon-3 me-0">
                      <inline-svg
                        src="/media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                </button>
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <!-- <div class="card-header">
                <div class="card-title mt-8">
                  <h2>Hybrid Çalışan Backend Developer</h2>
                </div>
              </div> -->
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="card-title mt-8">
                      <h2>Hybrid Çalışan Backend Developer</h2>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-3
                      justify-content-end
                      text-align-end
                      align-items-end
                    "
                  >
                  <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      mt-10

                    "
                    >İncele</a
                  ></router-link
                >
                  </div>
                </div>
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>Yazılım Geliştirici İlk Aşama Mülakatı</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat, başvurulan pozisyona yönelik uygulamalı bir değerlendirme içerir.
                </div>
                <p> <b>Tarih: 08.11.2024 - 01.01.2025</b> </p>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 5
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Minimum 4 yıl
                    iş tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    mühendislik bölümlerinden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Nodejs geçmişi
                    olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Typescript,
                    Javascript ve Java dillerine hakim
                  </div>

                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>
                    <em>4 adet daha...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">

<router-link to="/interview/invite-candidate">
   <a class="btn btn-sm btn-primary btn-active-primary my-1 ms-2                      me-2"
     >Aday Davet Et</a
   ></router-link
 >
 <a class="btn btn-sm btn-secondary btn-active-primary my-1                      me-2"
     >Kopyala</a
   >

 <button
   type="button"
   class="btn btn-sm btn-warning btn-active-light-primary my-1"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg src="/media/icons/duotune/art/art005.svg" />
     </span>
 </button>

 <button
   type="button"
   class="btn btn-sm btn-danger btn-active-danger my-1 ms-2"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg
         src="/media/icons/duotune/general/gen027.svg"
       />
     </span>
 </button>
</div>
              <!--end::Card footer-->

                            <!--begin::Card footer-->
                            <!-- <div class="card-footer flex-wrap pt-0">
                <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      my-1
                    "
                    >İncele</a
                  ></router-link
                >

               <router-link to="/interview/invite-candidate">
                  <a class="btn btn-sm btn-primary btn-active-primary my-1 ms-2                       me-2"
                    >Aday Davet Et</a
                  ></router-link
                >

                <button
                  type="button"
                  class="btn btn-sm btn-warning btn-active-light-primary my-1"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role"
                >
                  Düzenle
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger btn-active-danger my-1 ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role"
                >
                <span class="svg-icon svg-icon-3 me-0"
                          ><svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                              fill="black"
                            ></path></svg></span
                      >
                </button>
              </div> -->
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <div class="row">
                  <div class="col-md-10">
                    <div class="card-title mt-8">
                      <h2>Açık Pozisyonun Başlığı</h2>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-2
                      justify-content-end
                      text-align-end
                      align-items-end
                    "
                  >
                  <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      mt-10

                    "
                    >İncele</a
                  ></router-link
                >
                  </div>
                </div>
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>Mülakat başlığı</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Mülakat Açıklaması
                </div>
                <p> <b>Tarih: 08.11.2024 - 01.01.2025</b> </p>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 10
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    bölümlerden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> 2 yıl iş
                    tecrübesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Analiz becerisi
                    yüksek
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Projeyi
                    kendisininin gibi sahiplenebilen
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Zaman yönetimi
                    iyi
                  </div>

                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>
                    <em>2 adet daha...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">

<router-link to="/interview/invite-candidate">
   <a class="btn btn-sm btn-primary btn-active-primary my-1 ms-2                      me-2"
     >Aday Davet Et</a
   ></router-link
 >

 <a class="btn btn-sm btn-secondary btn-active-primary my-1                      me-2"
     >Kopyala</a
   >

 <button
   type="button"
   class="btn btn-sm btn-warning btn-active-light-primary my-1"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg src="/media/icons/duotune/art/art005.svg" />
     </span>
 </button>

 <button
   type="button"
   class="btn btn-sm btn-danger btn-active-danger my-1 ms-2"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg
         src="/media/icons/duotune/general/gen027.svg"
       />
     </span>
 </button>
</div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="card-title mt-8">
                      <h2>Ofisten Çalışan Fullstack Developer</h2>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-3
                      justify-content-end
                      text-align-end
                      align-items-end
                    "
                  >
                  <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      mt-10

                    "
                    >İncele</a
                  ></router-link
                >
                  </div>
                </div>
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>İlk Aşama Mülakatı</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat, yazılım geliştirme pozisyonu için adayların teknik becerilerini ve deneyimlerini değerlendirir.
                </div>
                <!--begin::Users-->
                <p> <b>Tarih: 08.11.2024 - 01.01.2025</b> </p>
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 42
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Minimum 4 yıl
                    iş tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    mühendislik bölümlerinden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>.NET Core'a
                    hakim
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Projede
                    eksikleri saptayıp ona göre zaman planı çıkartabilecek
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Database
                    yapıları hakkında bilgi sahibi
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">

<router-link to="/interview/invite-candidate">
   <a class="btn btn-sm btn-primary btn-active-primary my-1 ms-2                      me-2"
     >Aday Davet Et</a
   ></router-link
 >
 <a class="btn btn-sm btn-secondary btn-active-primary my-1                      me-2"
     >Kopyala</a
   >

 <button
   type="button"
   class="btn btn-sm btn-warning btn-active-light-primary my-1"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg src="/media/icons/duotune/art/art005.svg" />
     </span>
 </button>

 <button
   type="button"
   class="btn btn-sm btn-danger btn-active-danger my-1 ms-2"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg
         src="/media/icons/duotune/general/gen027.svg"
       />
     </span>
 </button>
</div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="card-title mt-8">
                      <h2>Hybrid Çalışan Software Specialist</h2>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-3
                      justify-content-end
                      text-align-end
                      align-items-end
                    "
                  >
                  <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      mt-10

                    "
                    >İncele</a
                  ></router-link
                >
                  </div>
                </div>
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>Yazılım Uzmanı İlk Aşama Mülakatı</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Yazılım Uzmanı pozisyonu için adayların teknik becerilerini ve deneyimlerini değerlendirir.
                </div>
                <!--begin::Users-->
                <p> <b>Tarih: 08.11.2024 - 01.01.2025</b> </p>

                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 54
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    bölümlerden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> 2 yıl iş
                    tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Yazılım
                    gereksinimleri hakkında bilgi sahibi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Teknik konular
                    ve rapor hazırlama konusunda tecrübesi ve hevesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Proje
                    eksiklerini saptayıp roadmap oluşturabilme yeteneği olan
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">

<router-link to="/interview/invite-candidate">
   <a class="btn btn-sm btn-primary btn-active-primary my-1 ms-2                      me-2"
     >Aday Davet Et</a
   ></router-link
 >
   <a class="btn btn-sm btn-secondary btn-active-primary my-1                      me-2"
     >Kopyala</a
   >


 <button
   type="button"
   class="btn btn-sm btn-warning btn-active-light-primary my-1"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg src="/media/icons/duotune/art/art005.svg" />
     </span>
 </button>

 <button
   type="button"
   class="btn btn-sm btn-danger btn-active-danger my-1 ms-2"
   data-bs-toggle="modal"
   data-bs-target="#kt_modal_update_role"
 >
 <span class="svg-icon svg-icon-3 me-0">
       <inline-svg
         src="/media/icons/duotune/general/gen027.svg"
       />
     </span>
 </button>
</div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->

          <!--begin::Add new card-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card h-md-100">
              <!--begin::Card body-->
              <div class="card-body d-flex flex-center">
                <!--begin::Button-->
                <button
                  type="button"
                  class="btn btn-clear d-flex flex-column flex-center"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_add_role"
                >
                  <!--begin::Illustration-->
                  <img
                    src="/media/illustrations/sketchy-1/4.png"
                    alt=""
                    class="mw-100 mh-150px mb-7"
                  />
                  <!--end::Illustration-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-3 text-gray-600 text-hover-primary">
                    Açık Pozisyon Ekle
                  </div>
                  <!--end::Label-->
                </button>
                <!--begin::Button-->
              </div>
              <!--begin::Card body-->
            </div>
            <!--begin::Card-->
          </div>
          <!--begin::Add new card-->
        </div>
      </div>
      <!--end:::Tab pane-->

      <!--begin:::Tab pane-->
      <div
        class="tab-pane fade"
        id="kt_customer_view_overview_events_and_logs_tab"
        role="tabpanel"
      >
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title mt-8">
                  <h2>Hybrid Çalışan Frontend Developer</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>08.11.2024 başlıklı mülakat</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat 08.11.2024 ve 05.12.2024 tarihleri arasında
                  geçerlidir.
                </div>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 5
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Minimum 3 yıl
                    iş tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    mühendislik bölümlerinden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Vuejs,
                    Typescript ile tecrübesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Tercihen
                    Angular ve React ile tecrübesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Ekip
                    çalışmasını seven ve Agile metodunu benimseyen
                  </div>

                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>
                    <em>7 adet daha..</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      my-1
                      me-2
                    "
                    >İncele</a
                  ></router-link
                >
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title mt-8">
                  <h2>Hybrid Çalışan Backend Developer</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>08.11.2024 başlıklı mülakat</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat 08.11.2024 ve 05.12.2024 tarihleri arasında
                  geçerlidir.
                </div>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 5
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Minimum 4 yıl
                    iş tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    mühendislik bölümlerinden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Nodejs geçmişi
                    olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Typescript,
                    Javascript ve Java dillerine hakim
                  </div>

                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>
                    <em>4 adet daha...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      my-1
                      me-2
                    "
                    >İncele</a
                  ></router-link
                >
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title mt-8">
                  <h2>Pozisyon Adı</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>Mülakat başlığı</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Mülakat Açıklaması
                </div>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 10
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    bölümlerden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> 2 yıl iş
                    tecrübesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Analiz becerisi
                    yüksek
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Projeyi
                    kendisininin gibi sahiplenebilen
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Zaman yönetimi
                    iyi
                  </div>

                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>
                    <em>2 adet daha...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      my-1
                      me-2
                    "
                    >İncele</a
                  ></router-link
                >
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title mt-8">
                  <h2>Ofisten Çalışan Fullstack Developer</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>05.11.2024 başlıklı mülakat</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat 05.11.2024 ve 01.01.2025 tarihleri arasında
                  geçerlidir.
                </div>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 42
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Minimum 4 yıl
                    iş tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    mühendislik bölümlerinden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>.NET Core'a
                    hakim
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Projede
                    eksikleri saptayıp ona göre zaman planı çıkartabilecek
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Database
                    yapıları hakkında bilgi sahibi
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      my-1
                      me-2
                    "
                    >İncele</a
                  ></router-link
                >
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title mt-8">
                  <h2>Hybrid Çalışan Software Specialist</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <h5>08.11.2024 başlıklı mülakat</h5>
                <div class="fw-bold fs-7 text-gray-600 mb-4">
                  Bu mülakat 08.11.2024 ve 05.12.2024 tarihleri arasında
                  geçerlidir.
                </div>
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">
                  Mülakat gönderilen aday sayısı: 54
                </div>
                <!--end::Users-->

                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> İlgili
                    bölümlerden mezun
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> 2 yıl iş
                    tecrübesi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Yazılım
                    gereksinimleri hakkında bilgi sahibi
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Teknik konular
                    ve rapor hazırlama konusunda tecrübesi ve hevesi olan
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span> Proje
                    eksiklerini saptayıp roadmap oluşturabilme yeteneği olan
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->

              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <router-link to="/interview/interview-detail">
                  <a
                    class="
                      btn btn-sm btn-secondary btn-active-primary
                      my-1
                      me-2
                    "
                    >İncele</a
                  ></router-link
                >
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
        </div>
      </div>
      <!--end:::Tab pane-->
    </div>
    <!--end:::Tab content-->

    <!--begin::Modal-->
    <div
      class="modal fade"
      id="kt_modal_update_role"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-750px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <h2 class="fw-bold">Pozisyonu Güncelle</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-icon-primary"
              data-kt-roles-modal-action="close"
            >
              <i class="ki-duotone ki-cross fs-1"
                ><span class="path1"></span><span class="path2"></span
              ></i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body scroll-y mx-5 my-7">
            <!--begin::Form-->
            <form
              id="kt_modal_update_role_form"
              class="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            >
              <!--begin::Scroll-->
              <div
                class="d-flex flex-column scroll-y me-n7 pe-7"
                id="kt_modal_update_role_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_update_role_header"
                data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
                data-kt-scroll-offset="300px"
                style="max-height: 541px"
              >
                <!--begin::Input group-->
                <div class="fv-row mb-10 fv-plugins-icon-container">
                  <!--begin::Label-->
                  <label class="fs-5 fw-bold form-label mb-2">
                    <span class="required">Pozisyon Adı</span>
                  </label>
                  <!--end::Label-->

                  <!--begin::Input-->
                  <input
                    class="form-control form-control-solid"
                    placeholder="Pozisyon adı girin"
                    name="role_name"
                    value="Frontend Developer"
                  />
                  <!--end::Input-->
                  <div
                    class="
                      fv-plugins-message-container
                      fv-plugins-message-container--enabled
                      invalid-feedback
                    "
                  ></div>
                </div>
                <!--end::Input group-->

                <div class="fv-row mb-5">
                  <!--begin::Label-->
                  <label class="fs-5 fw-bold form-label mb-2"
                    >İlan Açıklaması</label
                  >
                  <!--end::Label-->


                  <vue-editor v-model="positionDesc" class="form-control form-control-solid" style="padding: 0px"></vue-editor>

                </div>
                
                <!--begin::Permissions-->
                <div class="fv-row mb-5">
                  <!--begin::Label-->
                  <label class="fs-5 fw-bold form-label mb-2"
                    >Aranılan Özellikler</label
                  >
                  <!--end::Label-->

                  <!--begin::Input group-->
                  <div class="mb-6 fv-row">
                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      v-model="featureInput"
                      @keyup.enter="addFeature"
                      placeholder="Özellik ekleyin ve Enter'a basın"
                      v-on:keydown.enter.prevent
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->

                  <!--begin::Feature List-->
                  <div v-if="features.length" class="card-body pt-0">
                    <div class="d-flex flex-column text-gray-600">
                      <div
                        v-for="(feature, index) in features"
                        :key="index"
                        class="d-flex align-items-center py-2"
                      >
                        <span class="bullet bg-primary me-3"></span>
                        {{ feature }}
                      </div>
                    </div>
                  </div>
                  <!--end::Feature List-->



                </div>
                <!--end::Permissions-->
                
                <div class="mb-0 fv-row">
      <label class="d-flex align-items-center fs-5 fw-bold form-label mb-5">
        Mülakat Türü Seç
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Mülakat türünü özelleştirip mülakatlarınızı gerçekleştirebilirsiniz."
        ></i>
      </label>

      <div class="mb-0">
        <!-- Özel Mülakat -->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/finance/fin001.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Özel Mülakat</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Tamamen özelleştirilmiş sorular ile mülakat oluşturun.</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="1"
              @change="showCustomQuestionInput = true"
            />
          </span>
        </label>

        <!-- Star Metodolojisi -->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/graphs/gra006.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Star Metodolojisi</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Star metodolojisi ile adayınızdan kendini tanıtmasını ve
                mesleğe bakış açısını anlatmasını isteyin</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="2"
              @change="showCustomQuestionInput = false"
            />
          </span>
        </label>
      </div>
    </div>

    <div class="w-100">
      <!-- Sorular Alanı -->
      <div class="mb-5" v-if="showCustomQuestionInput">
        <label class="d-flex align-items-center fs-5 fw-bold form-label">Sorular</label>
        <div class="d-flex align-items-center mb-3">
          <!-- Soru Yazma Inputu -->
          <input
            type="text"
            v-model="newQuestion.text"
                        @keyup.enter="addQuestion"
            class="form-control form-control-lg form-control-solid me-3"
            placeholder="Sorunuzu buraya yazın"
            v-on:keydown.enter.prevent
          />

          <!-- Soru Tipi Seçme -->
          <select
            v-model="newQuestion.type"
            class="form-select form-select-lg me-3"
            aria-label="Soru Türü"
          >
            <option value="open">Açık Uçlu</option>
            <option value="single">Tek Seçenekli</option>
            <option value="multiple">Çok Seçenekli</option>
          </select>

          <!-- Yeni Soru Ekle Butonu -->
          <button type="button" @click="addQuestion" class="btn btn-primary">
            Soru Ekle
          </button>
        </div>

        <!-- Eğer Tek veya Çok Seçenekli ise, Opsiyon Ekleme Alanı Göster -->
        <div
          v-if="
            newQuestion.type === 'single' || newQuestion.type === 'multiple'
          "
          class="mb-3"
        >
          <input
            type="text"
            v-model="newOptionText"
            @keyup.enter="addOption"
            class="form-control form-control-sm me-3"
            placeholder="Opsiyon eklemek için yazın ve Enter'a basın"
            v-on:keydown.enter.prevent
          />
          <!-- Eklenen Opsiyonları Gösterme -->
          <ul class="list-group mt-2">
            <li
              v-for="(option, index) in newQuestion.options"
              :key="index"
              class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
              "
            >
              {{ option }}
              <button type="button"
                @click="removeOption(index)"
                class="btn btn-sm btn-danger"
              >
                Sil
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Eklenen Soruların Listesi -->
      <div v-if="questions.length > 0 && showCustomQuestionInput" class="mt-5">
        <h3 class="d-flex align-items-center fs-5 fw-bold form-label mb-4">Eklenen Sorular</h3>
        <ul class="list-group">
          <li
            v-for="(question, index) in questions"
            :key="index"
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div>
              <strong>Soru:</strong> {{ question.text }}<br />
              <strong>Tür:</strong>
              {{
                question.type === "open"
                  ? "Açık Uçlu"
                  : question.type === "single"
                  ? "Tek Seçenekli"
                  : "Çok Seçenekli"
              }}
              <ul
                v-if="question.options && question.options.length > 0"
                class="mt-2"
              >
                <li v-for="(option, i) in question.options" :key="i">
                  {{ option }}
                </li>
              </ul>
            </div>
            <button
              @click="removeQuestion(index)"
              class="btn btn-sm btn-danger"
            >
              Sil
            </button>
          </li>
        </ul>
      </div>
    </div>
              </div>
              <!--end::Scroll-->

              <!--begin::Actions-->
              <div class="text-center pt-15">
                <button
                  type="reset"
                  class="btn btn-light me-3"
                  data-kt-roles-modal-action="cancel"
                >
                  Vazgeç
                </button>

                <button
                  type="submit"
                  class="btn btn-primary"
                  data-kt-roles-modal-action="submit"
                >
                  <span class="indicator-label"> Güncelle </span>
                  <span class="indicator-progress">
                    Lütfen bekleyiniz...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { hideModal } from "@/presentation/helper/Dom";
import { JobPostingListModel } from "@/domain/interview/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interview/delete-job-posting/model/DeleteJobPostingModel";
import { UpdateJobPostingModel } from "@/domain/interview/update-job-posting/model/UpdateJobPostingModel";
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "NewInterviewList",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref(""); 
    const showCustomQuestionInput = ref(false);
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const interviewController =
      store.state.ControllersModule.interviewController;
    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getInterviewList = async (page) => {
      isChanged.value = false;
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interview: {},
        },
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      interviewController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              // totalItems.value = jobPosting.pagination.total;
              // currentPage.value = jobPosting.pagination.page;
              // pageCount.value = jobPosting.pagination.pageCount;
              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deleteInterview = (interviewId) => {
      const interviewDeleteModel: DeleteJobPostingModel = {
        id: interviewId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_INTERVIEW),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewController
            .deleteJobPosting(interviewDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isChanged.value = true;
                Swal.fire({
                  title: "İşlem başarılı!",
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_INTERVIEW),
                  icon: "success",
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPostingDate = () => {
      const jobPostingUpdateModel: UpdateJobPostingModel = {};
      jobPostingUpdateModel.id = selectedInterviewId.value;
      jobPostingUpdateModel.endDate = new Date(newEndDate.value);

      interviewController
        .updateJobPosting(jobPostingUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;

            Swal.fire({
              title: "İşlem başarılı!",
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_INTERVIEW),
              icon: "success",
            }).finally(() => {
              hideModal(changeDateRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updatePollToClosed = async (id?: number) => {
      // const jobPostingUpdateModel: UpdatePollModel = {};
      // jobPostingUpdateModel.id = id;
      // jobPostingUpdateModel.completed = true;
      // Swal.fire({
      //   title: "Emin misiniz?",
      //   text: "Bu anketi sonlandırmak ister misiniz?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: "Tamam",
      //   cancelButtonText: "İptal",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //     cancelButton: "btn fw-bold btn-light-danger",
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     interviewController
      //   .updatePoll(jobPostingUpdateModel)
      //   .then((response) => {
      //     if (response.isSuccess) {
      //       isChanged.value = true;
      //       Swal.fire({
      //         title: "İşlem başarılı!",
      //         text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
      //         icon: "success",
      //       })
      //     } else {
      //       swalNotification.error(
      //         response.error.cause.cause +
      //           t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
      //           response.error.code +
      //           response.error.cause.code,
      //         t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      //       );
      //     }
      //   })
      //   .catch((e) => {
      //     swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
      //   });
      //   }
      // });
    };

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate
        ? new Date(oldDate).toISOString().split("T")[0]
        : "";
      selectedInterviewId.value = id;
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getInterviewList(1);
      }
    });

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    
    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };


    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewList(1);
    });

    return {
      url,
      jobPostingList,
      deleteInterview,
      form,
      newEndDate,
      updateJobPostingDate,
      updatePollToClosed,
      changeDateRef,
      selectInterview,
      isLoading,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      showCustomQuestionInput,
      positionDesc,

    };
  },
});
</script>
